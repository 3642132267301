import i18next from "i18next"

const getLanguage = () => i18next.language || window.localStorage.i18nextLng

const normalize_language = () => {

    var browse_lang:string = getLanguage()

   //console.log('browse_lang', browse_lang)
    if (browse_lang.includes("it")) {
      browse_lang = "it"
    } else if (browse_lang.includes("fr")) {
      browse_lang = "fr"
    }else { 
      browse_lang = "en"
    }

    return browse_lang

}

export default normalize_language