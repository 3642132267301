import { NavLink } from "react-router-dom";
import { LANGUAGES } from "../constants";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import Parser from 'html-react-parser';
import normalize_language from "../helpers/languageHelper";
import i18n from "../languages/i18n";

const isActive = ({ isActive }: any) => `link ${isActive ? "active" : ""}`;

const changeLanguageHandler = (lang:string) =>
  {
   //console.log('lang', lang)
    
    i18n.changeLanguage(lang)
    var selected = LANGUAGES.filter(language => language.code.includes(lang))
   //console.log('selected', selected)

    

  }

const Language = () => {
      const [flag, setFlag] = useState('')
      const [lang, setLang] = useState('')
      var browse_lang:string = normalize_language()
      const { t, i18n } = useTranslation();
      if (flag === ''){
      var selected = LANGUAGES.filter(language => language.code.includes(browse_lang))
       //console.log('selected', selected)
      if (selected.length > 0 ) {
        setFlag(selected[0].flag)
      } 
      }
     
      
      
     

  return (
    <nav>
       <div className="row align-items-center">
        <ul className="navbar-nav align-items-center">
          <li className="nav-item dropdown dropdown-menu-center">
            <a className="nav-link dropdown-toggle" href="/#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false" aria-haspopup="true" onClick={(event) => {
              event.stopPropagation();
              
            }}>{Parser(flag)} { (lang === '') ? "Language" : lang }</a>
            <div className="dropdown-menu dropdown-menu-center" id="navbarDropdown" aria-labelledby="navbarDropdown">
              
              
              {LANGUAGES.map(({ code, label, flag}) => 
          (
            <a key={code} className="dropdown-item" href="/#" onClick={( event ) => {
              //event.stopPropagation();
              event.preventDefault();
             //console.log('code', code)
              changeLanguageHandler(code)
              setLang(label)
              setFlag(flag)
              
          }}> {label}</a>
      
        ))}
            </div>
          </li>
        </ul>
        </div>
        
     
      </nav>
  )
}

export default Language;