import React, { useContext, useEffect, useState } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import UserProvider, { UserContext } from './context/UserContext';

import { BrowserRouter, Route, Routes } from 'react-router-dom';
import routes from './Router';

import './languages/i18n';
import i18n from "./languages/i18n";
import { I18nextProvider, useTranslation } from 'react-i18next';

const App = () => {
  //const sass = require('sass');
  // var scssFilename = "../scss/main.scss";
  //const result = sass.compile(scssFilename);
  const { t, i18n } = useTranslation();
  const { user, saveUser } = useContext(UserContext);

  //saveUser({name:'-', email:'-' , mobile: '-', isLogged: false})

 //console.log('index', user);



  useEffect(() => {
   }, []);
  return (
   
     
        <>
          {' '}
          {user.isLogged === false ? (
            <BrowserRouter>
              <Routes>
                {routes.map((data, index) => (
                  <Route
                    path={data.path}
                    element={data.component(user)}
                    key={index}
                  />
                ))}
              </Routes>
              {/* <><Login user={user} /></> */}
            </BrowserRouter>
          ) : (
            <>
              <BrowserRouter>
                <Routes>
                  {routes.map((data, index) => (
                    <Route
                      path={data.path}
                      element={data.component(user)}
                      key={index}
                    />
                  ))}
                </Routes>
              </BrowserRouter>
            </>
          )}
        </>
      
  
  );
};

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
  <I18nextProvider i18n={i18n} defaultNS={'translation'}>
   <UserProvider>
      <App />
    </UserProvider>
    </I18nextProvider>
  </React.StrictMode>

  
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

