
const menus= [
  {
    id: 0,
    name: 'menu.title_0',
    icon: 'fa fa-tachometer',
    link: '/#'
  },

  {
    id: 1,
    name: 'menu.title_1',
    icon: 'fa fa-image',
    link: '/#evaluate-photo'
   /* namesub: [
      {
        id: 1,
        sub: 'Dashboard',
        links: '/',
      },
      {
        id: 2,
        sub: 'Transaction',
        links: '/transaction',
      } 
    ],*/
  },
  
  { id: 2, name: 'menu.title_2', 
  icon: 'fa fa-bar-chart',
  link: '/#your-revue' },
  
];

export const menusUser = [
  // { id: 2,
  //   name: "menu_user.title_2",
  //   link: "/#settings"
  // },
  {id: 3,
    name: "menu_user.title_1",
    link: "/#profile"
  },
  {id: 1, 
    divider: "-"
   },
  {
    id: 0,
    name: 'menu_user.title_0',
    link: '/logout'
  },
 
  
];

export default menus;
