import React, { useContext, useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { IUser } from '../@types/user';
import srvConn from '../helpers/srvConn';
import validationInput from '../helpers/validationInput';
import { useTranslation } from "react-i18next";
import Language from '../components/Language';
import i18next from 'i18next';
import ActivationCode from '../components/ActivationCode';
import { UserContext } from "../context/UserContext";
import { timeout } from '../helpers/usefulFunction';
import contracts from '../data/contract.json'
import normalize_language from '../helpers/languageHelper';
import Modals from '../components/ModalRevue';
import { title } from 'process';
import { IModal } from '../@types/modal';
import ModalRevue from '../components/ModalRevue';


export interface Props {
  user: IUser;
}



const Register = (props: Props) => {

  const {user, saveUser} = useContext(UserContext);
  const navigate = useNavigate();



  const { t } = useTranslation();
  const agree = useRef<HTMLInputElement>(null)
  const divPassword =useRef<HTMLDivElement>(null);
  const nameRef = useRef<HTMLInputElement>(null);
  const emailRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);
  const passwordConfRef = useRef<HTMLInputElement>(null);
  const submitRef = useRef<HTMLButtonElement>(null);

  const BulletListRef = useRef<HTMLUListElement>(null);
  const LengthCharRef = useRef<HTMLElement>(null);
  const BigLetterRef = useRef<HTMLElement>(null);
  const NumCharRef = useRef<HTMLElement>(null);
  const SpecialCharRef = useRef<HTMLElement>(null);

  const [message, setMessage] = useState('');
  const [strength, setStrength] = useState(0);
  const [nameMsg, setNameMsg] = useState('');
  const [messagePassRepeat, setMessagePassRepeat] = useState('');
  const [progress, setProgress] = useState('');
  const [progressPassRepeat, setProgressPassRepeat] = useState('');
  const [hidePassword, setHidePassword] = useState(true);
  const [password, setPassword] = useState('');
  const [stateDisabledBtn, setStateDisabledBtn] = useState(true);
  const [response, setResponse] = useState({} as any);
  const [activation, setActivation] = useState(false);

  const [responseMsg, setResponseMsg] =  useState('');

  var browse_lang:string = normalize_language()
  const filteredData = contracts.filter((item) => item.language.includes(browse_lang) );

  useEffect(() => {
    if (nameRef.current !== null) {
   

    validateData();
  }


  }, [message, nameRef, emailRef, progressPassRepeat, user, strength, agree]);


  const validateData = () => {
    try {
     //console.log("strength", strength)
     //console.log("repeat", progressPassRepeat)
     //console.log("agree",agree.current!.checked)
    if (nameRef !== null) {
    if (
      progressPassRepeat === '100%' &&
      strength === 5 &&
      nameRef.current!.className.includes('is-valid') &&
      emailRef.current!.className.includes('is-valid') && agree.current!.checked === true
    ) {
     //console.log('abilitato');
      setStateDisabledBtn(false);
    } else {
     //console.log('disabilitato');
      setStateDisabledBtn(true);
    }
  }
  } catch(e) {
    // oh no! the fetch failed, we have no data to render!
   //console.log("error", e)
  }
  };

  const handleRepeatPassword = (passwordValue: string) => {
    if (passwordValue !== passwordRef.current!.value) {

      setProgressPassRepeat(`${0 * 100}%`);
      setMessagePassRepeat(t("form_signup.password_not_match"));
    
    } else {
      setProgressPassRepeat(`${1 * 100}%`);
      setMessagePassRepeat(t("form_signup.password_match"));
      
    }
  };

  const getActiveColorRepeat = (type: string) => {
    if (type === t("form_signup.password_match")) return '#8BC926';
    return '#FF0054';
  };

  const handlePassword = (passwordValue: string) => {
    const strengthChecks = {
      length: 0,
      hasUpperCase: false,
      hasLowerCase: false,
      hasDigit: false,
      hasSpecialChar: false,
    };

    strengthChecks.length = passwordValue.length >= 8 ? 1 : 0;
    strengthChecks.hasUpperCase = /[A-Z]+/.test(passwordValue);
    strengthChecks.hasLowerCase = /[a-z]+/.test(passwordValue);
    strengthChecks.hasDigit = /[0-9]+/.test(passwordValue);
    strengthChecks.hasSpecialChar = /[^A-Za-z0-9]+/.test(passwordValue);

    BulletListRef.current!.className = 'list-unstyled mb-0';

    if ( strengthChecks.length) {
      LengthCharRef.current!.className = 'fa fa-check me-2 text-success';
    } else {
      LengthCharRef.current!.className = 'fa fa-times me-3 text-danger';
    }

    if (strengthChecks.hasUpperCase) {
      BigLetterRef.current!.className = 'fa fa-check me-2 text-success';
    } else {
      BigLetterRef.current!.className = 'fa fa-times me-3 text-danger';
    }

    if (strengthChecks.hasDigit) {
      NumCharRef.current!.className = 'fa fa-check me-2 text-success';
    } else {
      NumCharRef.current!.className = 'fa fa-times me-3 text-danger';
    }
    if (strengthChecks.hasSpecialChar) {
      SpecialCharRef.current!.className = 'fa fa-check me-2 text-success';
    } else {
      SpecialCharRef.current!.className = 'fa fa-times me-3 text-danger';
    }

    let verifiedList = Object.values(strengthChecks).filter((value) => value);

    let strength =
      verifiedList.length === 5
        ? t("form_signup.strenght.strong")
        : verifiedList.length >= 2
        ? t("form_signup.strenght.medium")
        : t("form_signup.strenght.weak");

    setPassword(passwordValue);
    setProgress(`${(verifiedList.length / 5) * 100}%`);
    setMessage(strength);
    setStrength(verifiedList.length)

   //console.log('verifiedList: ', `${(verifiedList.length / 5) * 100}%`);

    handleRepeatPassword(passwordConfRef.current!.value);
  };

  const getActiveColor = (type: number) => {
    if (type === 5) return '#8BC926';
    if (type === 2) return '#FEBD01';
    return '#FF0054';
  };

  const submitClick = async (event: any) => {
    event.preventDefault();

    var srvRoute = '/user/signup';
    var lang = browse_lang

   //console.log('language', lang)
    var postData = {
      email: emailRef.current!.value,
      password: passwordRef.current!.value,
      fullname: nameRef.current!.value,
      language: lang
    };

    let res = await srvConn(srvRoute, postData);
    

    if (res.success && res.response.message.includes('not') ) {
      //User create but not activated yet
     //console.log("postData",postData)
      
      if (res.response.message !== null) {
      setResponse(res);
      saveUser({name: postData['fullname'], email:postData['email'], isLogged: false, password: postData['password'], isActive:false, language: postData['language'] })
     
      res.response.msg = t("form_signup.response_success")
      
      await timeout(5000)
       
      navigate("/activation-client")
     //console.log('res active', res);
      }
     
      

     //console.log('res', res);
      //setResponse(res);
    } else if (res.success && !res.response.message.includes('not')) {


      saveUser({name: postData['fullname'], email:postData['email'], isLogged: true, password: postData['password'], isActive:true, language: postData['language'] })
  
      res.response.msg = t("form_signup.activation_response_success")
      setResponse(res);

      await timeout(5000)
      navigate("/")
     //console.log('res active', res);

    } else if (!res.success) {
     //console.log('error', Array.isArray(res.error));
      if (!Array.isArray(res.error?.detail)) {
      var value_error = res.error!.includes('Wrong data')
        ? t("form_signup.response_error_duplicate")
        : 'Error: ' + res.error;

      setResponse({ error: value_error });
    } else {
      value_error = t("eval_photo.error_response_message")
      setResponse({ error: value_error });
    }
    }
  };

  var myModal :IModal = {
    id: 'revueModal',
    title: "Contracts",
    modalSize:"modal-dialog-scrollable modal-xl",
    body:'',
    footer:'<p></p>'}


  return (Object.keys(response).length === 0) ? (
   <> 
    
    <section className="ftco-section">
      
		<div className="container">
    {

filteredData.map( ( elem) => {
  myModal.body = elem.description;
 return (
  <>
<ModalRevue key={elem.language} user={user} modal={myModal} />
</>
  );})
      }
			<div className="row justify-content-center">
				<div className="col-md-12 col-lg-10">
					<div className="wrap d-md-flex">
       
						<div className="img" style={{backgroundImage:  'url("./images/bg-1.jpg")',}}>
			      </div>
						<div className="login-wrap p-4 p-md-5">
			      	<div className="d-flex">
			      		<div className="w-100">
							<div><img src='images/logo.png' alt="logo" width="200" /></div>
							<h4 className="mb-4">Visual Impact Index</h4><p>&nbsp;</p>
              <h3 className="text-center mb-4">{t("form_signup.title")}</h3>
			      		</div>
                
									
								</div>
                { user.isActive ? (<><p>{t('form_signup.activation_response_success')}</p></>) : (
                  <>
                  <form action="#">
                      <div className="form-group">
                        <label className="mb-1">
                          <strong>{t("form_signup.name")}</strong>
                        </label>
                        <div className="input-container">
                          <input
                            ref={nameRef}
                            onChange={({ target }) => {
                              let val = validationInput(
                                target.value,
                                'minchars'
                              );

                              if (val!.success) {
                                nameRef.current!.className = `form-control ${
                                  val!.class
                                }`;
                                setNameMsg('');

                                validateData();
                              } else {
                                nameRef.current!.className = `form-control ${
                                  val!.class
                                }`;
                                setNameMsg(val!.message);

                                validateData();
                              }
                            }}
                            type="text"
                            className="form-control"
                            placeholder="John Smith"
                          />
                        </div>
                      </div>
                      <p className="text-danger">{nameMsg}</p>
                      <div className="form-group">
                        <label className="mb-1">
                          <strong>{t("form_signup.email")}</strong>
                        </label>
                        <div className="input-container">
                          <input
                            ref={emailRef}
                            type="email"
                            className="form-control"
                            placeholder="hello@example.com"
                            onChange={({ target }) => {
                              let val = validationInput(target.value, 'email');

                              if (val!.success) {
                                emailRef.current!.className = `form-control ${
                                  val!.class
                                }`;
                              } else {
                                emailRef.current!.className = `form-control ${
                                  val!.class
                                }`;
                              }
                            }}
                          />
                        </div>
                        <div className="form-group">
                          <label className="mb-1">
                            <strong>{t("form_signup.password")}</strong>
                          </label>
                          <div ref={divPassword} className="input-container-1 form-control">
                            <div className="input-box">
                              <input
                                ref={passwordRef}
                                onChange={({ target }) => {
                                  divPassword.current!.focus();
                                  handlePassword(target.value);
                                }}
                                type={hidePassword ? 'password' : 'text'}
                                className="form-control-1 input"
                              />
                              <a href="/#"
                                className="toggle-btn"
                                onClick={(event) => {
                                  event.preventDefault();
                                  setHidePassword(!hidePassword);
                                }}
                              >
                                <span
                                  className="fa fa-eye"
                                  style={{
                                    color: !hidePassword
                                      ? '#FF0054'
                                      : '#c3c3c3',
                                  }}
                                >
                                  <span className="sr-only"> visibility</span>
                                </span>
                              </a>
                            </div>

                            <div className="progress-bg">
                              <div
                                className="progress"
                                style={{
                                  width: progress,
                                  backgroundColor: getActiveColor(strength),
                                }}
                              ></div>
                            </div>
                          </div>
                        </div>
                        {password.length !== 0 ? (
                          <p
                            className="message"
                            style={{ color: getActiveColor(strength) }}
                          >
                            {t("form_signup.password_is")} {message}
                          </p>
                        ) : null}

                        <div
                          className="alert px-4 py-3 mb-0"
                          role="alert"
                          data-mdb-color="warning"
                          id="password-alert"
                        >
                          <ul
                            ref={BulletListRef}
                            className="list-unstyled mb-0 d-none"
                          >
                            {t("form_signup.password_check")}
                            <li className="requirements leng">
                              <i
                                ref={LengthCharRef}
                                className="fa fa-check me-2"
                              ></i>
                               {t("form_signup.least_chars")}
                            </li>
                            <li className="requirements big-letter">
                              <i
                                ref={BigLetterRef}
                                className="fa fa-check me-2"
                              ></i>
                             {t("form_signup.least_big_letter")}
                            </li>
                            <li className="requirements num">
                              <i
                                ref={NumCharRef}
                                className="fa fa-check me-2"
                              ></i>
                             {t("form_signup.least_number")}
                            </li>
                            <li className="requirements special-char">
                              <i
                                ref={SpecialCharRef}
                                className="fa fa-check me-2"
                              ></i>
                              {t("form_signup.least_special_char")}
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="form-group">
                        <label className="mb-1">
                          <strong>{t("form_signup.repeat_password")}</strong>
                        </label>
                        <div className="input-container-1 form-control">
                          <div className="input-box">
                            <input
                              ref={passwordConfRef}
                              onChange={({ target }) => {
                                handleRepeatPassword(target.value);
                              }}
                              type={hidePassword ? 'password' : 'text'}
                              className="form-control-1 input"
                            />
                            <a
                              href="#"
                              className="toggle-btn"
                              onClick={() => {
                                setHidePassword(!hidePassword);
                              }}
                            >
                              <span
                                className="flaticon-057-eye"
                                style={{
                                  color: !hidePassword ? '#FF0054' : '#c3c3c3',
                                }}
                              >
                                <span className="sr-only"> visibility</span>
                              </span>
                            </a>
                          </div>

                          <div className="progress-bg">
                            <div
                              className="progress"
                              style={{
                                width: progressPassRepeat,
                                backgroundColor:
                                  getActiveColorRepeat(messagePassRepeat),
                              }}
                            ></div>
                          </div>
                        </div>
                      </div>
                      {password.length !== 0 ? (
                        <p
                          className="message"
                          style={{
                            color: getActiveColorRepeat(messagePassRepeat),
                          }}
                        >
                          {messagePassRepeat}
                        </p>
                      ) : null}
                      <div className="form-group d-md-flex">
		            	<div className="w-60 text-left">
			            	<label className="checkbox-wrap checkbox-primary mb-0 w-100">{t("form_signup.accept_contract")}
									  <input ref={agree} type="checkbox" onClick={(e) => {validateData()}}/>
									  <span className="checkmark"></span>
										</label>
									</div>
									<div className="w-40 text-md-right">
                  <button type="button" className="btn btn-secondary" data-bs-toggle="modal" data-bs-target={`#${myModal.id}`}>{t("form_signup.terms_link")}</button>
                 </div>
		            </div>
                      <div className="text-center mt-4">
                        <button
                          type="submit"
                          onClick={(e) => submitClick(e)}
                          disabled={stateDisabledBtn}
                          ref={submitRef}
                          className="btn btn-primary btn-block">
                          {t("form_signup.button")}
                        </button>
                      </div>
                    </form>
                  </>
                )}

                    
                    <div className="new-account mt-3">
                      <p>
                      {t("form_signup.already_an_account")}{' '}
                        <Link to={'/login'}>{t("form_signup.sign_in")}</Link>
                      </p>
                    </div>
                    </div>
            </div>
		      </div>
				</div>
        <Language />
			</div>
      
	</section>
  </>
  ) : (
    <section className="ftco-section">
		<div className="container">

			<div className="row justify-content-center">
				<div className="col-md-12 col-lg-10">
					<div className="wrap d-md-flex">
						<div className="img" style={{backgroundImage:  'url("./images/bg-1.jpg")',}}>
			      </div>
						<div className="login-wrap p-4 p-md-5">
			      	<div className="d-flex">
			      		<div className="w-100">
							<div><img src='images/logo.png' alt="logo" width="200" /></div>
                    <h4 className="text-center mb-4">Account</h4>
                    {response.success ? response.response.msg : response.error}

                    {response.error ? (<> <p>
                      {t("form_signup.already_an_account")}{' '}
                        <Link to={'/login'}>{t("form_signup.sign_in")}</Link>
                      </p></>) :null}
                    
                   
                  </div>
                </div>
              </div>
              </div>
		      </div>
				</div>
        <Language />
			</div>
	</section>
  );
};

export default Register;


