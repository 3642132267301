import { IModal } from "../@types/modal"
import { IUser } from "../@types/user"

export interface Props {
  user: IUser,
  modal:IModal
}

const ModalRevue = ({user, modal}: Props) => {

  return(
  <>
  <div className={`modal fade`} id={`${modal.id}`} aria-labelledby={`${modal.id}Label`} tabIndex={-1} aria-hidden="true">
  <div className={`modal-dialog ${modal.modalSize}`}>
    <div className="modal-content">
      <div className="modal-header">
      <h1 className="modal-title fs-5" id="RevueModalToggleLabel">{modal.title}</h1>
        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={(e) => {}}></button>
      </div>
      <div className="modal-body"dangerouslySetInnerHTML={{__html:modal.body}}>
    
      </div>
      <div className="modal-footer" dangerouslySetInnerHTML={{__html:modal.footer}}>
       
       </div>
    </div>
  </div>
</div>

  </>
)
}

export default ModalRevue


