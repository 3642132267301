import React, { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import { IUser, UserContextInterface } from '../@types/user';
import UserProvider, { UserContext } from '../context/UserContext';
import { Link, redirect } from 'react-router-dom';
import validationInput from '../helpers/validationInput';
import srvConn from '../helpers/srvConn';
import { t } from 'i18next';
import { timeout } from '../helpers/usefulFunction';
import Language from '../components/Language';


export interface Props {
  user: IUser;
}

const Login = (props: Props) => {
  const navigate = useNavigate();
  const emailRef = useRef<HTMLInputElement>(null);
  const passRef = useRef<HTMLInputElement>(null);
  const errorMsg = useRef<HTMLDivElement>(null);
  const successRef = useRef<HTMLDivElement>(null);

  const { user, saveUser } = useContext(UserContext) as UserContextInterface;
  const [loginUser, setLoginUser] = useState(user as IUser);

  const [response, setResponse] = useState('');

  const OnClickSubmit = async (e: any) => {
    e.preventDefault();

    errorMsg.current!.className =  errorMsg.current!.className.replace(" show", '');
    successRef.current!.className =  successRef.current!.className.replace(" show", '');
    
   //console.log('email', emailRef.current!.value);
    var emailVal = validationInput(emailRef.current!.value, 'email');
    if (emailVal!.success === true) {
      emailRef.current!.className = 'form-control';
    } else {
      emailRef.current!.className = `form-control ${emailVal!.class}`;
    }

    if (passRef.current!.value === '') {
      passRef.current!.className = 'form-control is-invalid';
    } else {
      passRef.current!.className = 'form-control valid';
      
    }

   //console.log('pwd', passRef.current!.value);
    if (emailVal!.success && passRef.current!.className.includes(' valid')) {
    var srvRoute = '/user/login';
         
   
    var postData = {
      email: emailRef.current!.value,
      password: passRef.current!.value,
    };

   
      let res = await srvConn(srvRoute,postData);
   //console.log("res",res);

    if (res.success && res.response.message.includes('not') ) {
      //user not activated
      //setResponse("User correct login");
     //console.log("res",res);
      setResponse(t("form_signin.user_not_active"));
      successRef.current!.className  = successRef.current!.className.includes(' show') ?  successRef.current!.className : successRef.current!.className + ' show';
     
      await timeout(2000)
      
      navigate("/activation-client")

    } else if (res.success && !res.response.message.includes('not') ) {
      //setResponse("User correct login");
     //console.log("res",res);
      setResponse(t("form_signin.correct_login"));
      successRef.current!.className  = successRef.current!.className.includes(' show') ?  successRef.current!.className : successRef.current!.className + ' show';
       saveUser({
        email: emailRef.current!.value,
        password: passRef.current!.value,
        isActive: true,
        isLogged: true
      })

 
      
      await timeout(2000)
      navigate("/")
    
      

      } else {
       //console.log("error",res);
        //var value_error = (res.error!.includes("E11000 duplicate key")) ? "User not exist" : "User not exist";
        setResponse(t("form_signin.response_error_duplicate"));

        errorMsg.current!.className  = errorMsg.current!.className.includes(' show') ?  errorMsg.current!.className : errorMsg.current!.className + ' show';
       
      }


      /*const bootstrap = require("bootstrap");
      const toast = new bootstrap.Toast("#liveToast");
      toast.show();*/

    }

    /*  setLoginUser({name:'val', email: 'valeria.sinnati@gmail.com', isLogged: true, mobile: '11134'});
        saveUser({ name:'val', email: 'valeria.sinnati@gmail.com', isLogged: true, mobile: '11134'} as IUser);
       //console.log('user login', user);

        navigate('/');
       */
  };

  const dismissClick = (e:any) => {
    
    errorMsg.current!.className = errorMsg.current!.className.replace(" show", '');
    successRef.current!.className = successRef.current!.className.replace(" show", '');
    
  }

  useEffect(() => {
    if (user.isLogged) {
     //console.log('if isLogged', user.isLogged);
      
    }
  }, [user, emailRef, passRef]);

  return (
    
    <section className="ftco-section">
		<div className="container">

			<div className="row justify-content-center">
				<div className="col-md-12 col-lg-10">
					<div className="wrap d-md-flex">
						<div className="img" style={{backgroundImage:  'url("./images/bg-1.jpg")',}}>
			      </div>
						<div className="login-wrap p-4 p-md-5">
			      	<div className="d-flex">
			      		<div className="w-100">
							<div><img src='images/logo.png' alt="logo" width="200" /></div>
							<h4 className="mb-4">Visual Impact Index</h4><p>&nbsp;</p>
			      			<h3 className="mb-4">{t("form_signin.title")}</h3>
			      		</div>
        
								</div>
               
							<form action="#" className="signin-form" method="post" autoComplete='on' onSubmit={(e) => OnClickSubmit(e)}>
              <div ref={errorMsg} className="alert alert-danger alert-dismissible fade">
									<svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round" className="me-2"><polygon points="7.86 2 16.14 2 22 7.86 22 16.14 16.14 22 7.86 22 2 16.14 2 7.86 7.86 2"></polygon><line x1="15" y1="9" x2="9" y2="15"></line><line x1="9" y1="9" x2="15" y2="15"></line></svg>
									<strong>Error!</strong>  {response}
									
                                    <button type="button" className="close" aria-label="Close" onClick={(e) => dismissClick(e)}>
  <span aria-hidden="true">×</span>
</button></div>
            <div ref={successRef} className="alert alert-success alert-dismissible fade" role="alert">
              <button type="button" className="close" data-dismiss="alert" onClick={(e) => dismissClick(e)} aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
              <strong> {response}</strong> 
            </div>
			      		<div className="form-group mb-3">
			      			<label className="label"  htmlFor="username">{t("form_signin.email")}</label>
			      			<input type="text" ref={emailRef} name='username' className="form-control" placeholder="Username" autoComplete='username' required />
			      		</div>
		            <div className="form-group mb-3">
		            	<label className="label" htmlFor="password">{t("form_signin.password")}</label>
		              <input type="password" ref={passRef} name='password' className="form-control" placeholder="Password" autoComplete='current-password' required />
		            </div>
		            <div className="form-group">
		            	<button type="submit" className="form-control btn btn-primary rounded submit px-3">Sign In</button>
		            </div>
		            <div className="form-group d-md-flex">
		            	<div className="w-50 text-left">
			            	<label className="checkbox-wrap checkbox-primary mb-0">{t("form_signin.remember_checkbox")}
									  <input type="checkbox"/>
									  <span className="checkmark"></span>
										</label>
									</div>
									<div className="w-50 text-md-right">
                 { /*   DISABLED FORGOT PASSWORD
                 <Link to={'/password-recovery'}>
                  {t("form_signin.forgot_password")}
                          </Link> */}
									</div>
		            </div>
                
		          </form>
              <p className="text-center"> {t("form_signin.not_account_lb")}{' '}
                        <Link to={'/register'}>{t("form_signin.sign_up_lb")}</Link></p>
		        </div>
            </div>
		      </div>
				</div>
        <Language />
			</div>
     
	</section>
  
   
  );
};

export default Login;
