import React from "react";

const Preloader = () => {

    return (
       <div className='container-fluid w-100 d-flex justify-content-center m-top-50'>
        <div className={`loading-anim gelatine show`}>
        <span className='visually-hidden'>Loading...</span>
       </div></div>
    )
}

export default Preloader;