import React, { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import { IUser, UserContextInterface } from '../@types/user';
import UserProvider, { UserContext } from '../context/UserContext';
import { Link, redirect } from 'react-router-dom';
import validationInput from '../helpers/validationInput';
import srvConn from '../helpers/srvConn';
import { t } from 'i18next';
import { timeout } from '../helpers/usefulFunction';


export interface Props {
  user: IUser;
}

const Logout = (props: Props) => {



  const { user, saveUser } = useContext(UserContext) as UserContextInterface;



  useEffect(() => {
    if (user.isLogged) {
      user.isLogged = false
      saveUser(user)
     //console.log('user logout', user);
    }
  }, [user]);

  return (
    
    <section className="ftco-section">
		<div className="container">

			<div className="row justify-content-center">
				<div className="col-md-12 col-lg-10">
					<div className="wrap d-md-flex">
						<div className="img" style={{backgroundImage:  'url("./images/bg-1.jpg")',}}>
			      </div>
						<div className="login-wrap p-4 p-md-5">
			      	<div className="d-flex">
			      		<div className="w-100">
							<div><img src='images/logo.png' alt="logo" width="200" /></div>
							<h4 className="mb-4">Visual Impact Index</h4><p>&nbsp;</p>
			      			<h3 className="mb-4">{t("logout.title")}</h3>
			      		</div>
                
									
								</div>
			      	<h5>{t("logout.success")}</h5>

		        </div>
            </div>
		      </div>
				</div>
			</div>
	</section>
  
   
  );
};

export default Logout;
