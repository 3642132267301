import React, { lazy, startTransition, useContext, useEffect, useState } from "react"

//import ContentBody from "../components/ContentBody";

import Header from "../components/Header";
import Nav from "../components/Nav";
import SideBar from "../components/SideBar";
import { UserContext } from "../context/UserContext";
import Chatbot from '../components/Chatbox';
import { IUser } from "../@types/user";
import Login from "./Login";
import { BrowserRouter, useLocation } from "react-router-dom";
import Settings from "../components/Settngs";
import Profile from "../components/Profile";
import Footer from "../components/Footer";
import EvaluatePhoto from "../components/EvaluatePhoto";
import Preloader from "../components/Preloader";



export interface Props {
  user: IUser,

}
const ContentBody = lazy(() => import('../components/ContentBody'));
const StoricRevue = lazy(() => import('../components/StoricRevue'));
const Home = (props : Props) => {
  const { pathname, hash, key } = useLocation();
 //console.log("hash", hash)

  const {user, saveUser} = useContext(UserContext);

  const [showWrapper, setShowWrapper] = useState('');

  const [menuToggle, setMenuToggle] = useState(false);

  const [loaded, setLoaded] = useState(false);


  const toggleHb = (show: boolean) => {
    menuToggle == false ? setMenuToggle(true) : setMenuToggle(false);
  }
  useEffect(() => {

     
    
  //props.enablePreloader(true);
  //console.log('user home',user);
  //console.log('isLogged',user.isLogged);

  
    
}, [ user, loaded]);

return (
  <>
  { (user.isLogged === undefined || user.isLogged === false) ? (
    <Login user={user} />
  ) : (
    <>
    <Nav toggleHb={toggleHb} />
    <main className="d-flex flex-nowrap">
    
    <SideBar />
    
    {hash === "" ? (<><React.Suspense fallback={<><Preloader /></>}><ContentBody user={user} /></React.Suspense></>
             ):(<>
      {(hash === "#settings") ? (<><Settings /></>) : null}
      {(hash === "#profile") ? (<Profile user={user} />) : null}
      {(hash === "#evaluate-photo") ? (<EvaluatePhoto user={user} />) : null}
      {(hash === "#your-revue") ? (<><React.Suspense fallback={<><Preloader /></>}><StoricRevue user={user} /></React.Suspense></>
             ) : null}
      

    </>)  } 
    
    </main>
    

    </>
    )
  }
  </>
  )
}


export default Home;