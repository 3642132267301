import React, { useContext, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";
import { UserContext } from "../context/UserContext";
import menus, { menusUser } from "../menu";
import { t } from "i18next";

const SideBar = () => {
    const menu = useRef<HTMLInputElement>(null);
    const menuDesktop = useRef<HTMLInputElement>(null);
    
  
    const {user, saveUser } =useContext(UserContext);
    const { pathname, hash, key } = useLocation();

    const [activeIndex, setActiveIndex] = useState(null);
    const [activeMenuPersonalIndex, setActiveMenuPersonalIndex] = useState(null);
    const handleOnClick = (index : any, menuName:string) => {
      if (menuName === "sidebar") {
        setActiveIndex(index);
        setActiveMenuPersonalIndex(null);
      } else {
        setActiveMenuPersonalIndex(index);
        setActiveIndex(null);
      }
       
  };


  const [width, setWidth] = useState<number>(window.innerWidth);

function handleWindowSizeChange() {
    setWidth(window.innerWidth);
   //console.log("width",width)
}
const isMobile = width <= 768;
useEffect(() => {
var classMenu = ''
  if (isMobile)
    {

      //console.log("mobile", isMobile)
      menu.current!.style.width ='4.5rem'
      classMenu = "sidebar-mobile d-flex flex-column flex-shrink-0 bg-light"
      menu.current!.className = classMenu

    } else {
      //console.log("mobile", isMobile)
      menu.current!.style.width ="280px"
      classMenu = "sidebar d-flex flex-column flex-shrink-0 p-3 bg-light justify-content-between"
      menu.current!.className = classMenu
      
    }
    var initial = document.getElementById('initial-profile');
    var inUs = ''
    var splitName
    if (user.name && user.name.includes(' ')) {
       splitName = user.name.split(' ')
       //console.log('split', splitName)
       //console.log('len', splitName.length)
       // inUs = splitName[0].charAt(0) + splitName[1].charAt(0)

    } else {
        if (user.name) {
          inUs = user.name?.charAt(0)
        }
         else if (user.email) {
          if (user.email.includes('.')) {
            splitName = user.email.split('.')
           //console.log('split', splitName)
           //console.log('len', splitName.length)
            inUs = splitName[0].charAt(0) + splitName[1].charAt(0)
          } else {
            inUs = user.email?.charAt(0)
          }
          

        }
        
    }
    if (initial) initial!.innerText = inUs

    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
        window.removeEventListener('resize', handleWindowSizeChange);
    }
    


}, [width, isMobile, user]);




  return (

  <><nav ref={menu} className="sidebar d-flex flex-column flex-shrink-0 p-3 bg-light justify-content-between" style={{width: 280}}>
  
  <ul className={isMobile ? "nav nav-pills nav-flush flex-column mb-auto text-center" :"nav nav-pills flex-column mb-auto" }>
  {
                                        menus.map((data,index) => (
                                            <li key={index} onClick={()=> handleOnClick(index, "sidebar")}>
                                              {isMobile ? (<>
                                                { (data.link ? ( <Link className={`nav-link py-3 border-bottom ${activeIndex === index ? 'active bg-primary' : ''}`} to={data.link} title="" data-bs-toggle="tooltip" data-bs-placement="right" data-bs-original-title={t(data.name)}> {data.icon ? (<><i className={data.icon}></i></>) : (<></>)}</Link>) : ( <Link  className={`${activeIndex === index ? 'active  bg-primary nav-link py-3 border-bottompy-3 border-bottom' : 'nav-link py-3 border-bottom'} ` }  to="#">{data.icon ? (<><i className={data.icon}></i></>) : (<></>)}</Link>))}
                                               
                                               
                                              </>) : (<>
                                                { (data.link ? ( <Link className={`nav-link link-dark ${activeIndex === index ? 'active bg-primary' : ''}`} to={data.link}>{data.icon ? (<><i className={data.icon}></i></>) : (<></>)}{t(data.name)} </Link>) : ( <Link  className={`${activeIndex === index ? 'active nav-link link-dark bg-primary' : 'nav-link link-dark'} ` }  to="#">{data.icon ? (<><i className={data.icon}></i></>) : (<></>)}{t(data.name!)}</Link>))}
                                               
                                              
                                              </>)}
                                               
                                                
                                            </li>
                                        ))
                                    }
    
  </ul>
  <hr />
  <div className="dropdown">
    <a href="#" className={isMobile ? "d-flex align-items-center justify-content-center p-3 link-dark text-decoration-none dropdown-toggle" : "d-flex align-items-center link-dark text-decoration-none dropdown-toggle"} id="dropdownUser2" data-bs-toggle="dropdown" aria-expanded="false">
      
      <div id="initial-profile" className="rounded-circle" style={isMobile ? { marginLeft:-4} : { marginRight:8}}></div>
      <strong style={isMobile ? {display:'none', margin: 0} : {display:'block'}}>{user.name ? user.name : user.email}</strong>
    </a>
    <ul className="dropdown-menu text-small shadow" aria-labelledby="dropdownUser2" style={{}}>
    {
                                        menusUser.map((data,index) => (
                                          
                                          <li key={index} onClick={()=> handleOnClick(index, "MenuPersonal")} >
                                             
                                                {data.divider === undefined ? (<> <a className={`dropdown-item ${activeMenuPersonalIndex === index ? 'active bg-dark' : ''}`}href={data.link}>{t(data.name)}
                                                </a></>) : (<><hr className="dropdown-divider" /></>)}
                                               
                                               </li>
                                                
                                            
                                        ))
                                    }
    
   
    </ul>
  </div>
</nav><div className="b-divider b-vr"></div></>
  )

}
export default SideBar