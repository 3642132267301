import { t } from "i18next"
import { Ref, RefObject, SetStateAction, useContext, useEffect, useMemo, useRef, useState } from "react";
import { IUser, UserContextInterface } from "../@types/user";
import {UserContext} from "../context/UserContext";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css'
import '../select.css'
import countries from '../data/country.json';
import srvConn from "../helpers/srvConn";
import { timeout } from "../helpers/usefulFunction";

export interface Props {
  user: IUser
}

const Profile = (props: Props) => {
  const [country, setCountry] = useState('');

 const emailRef = useRef<HTMLInputElement>(null);
 const countryRef = useRef<HTMLInputElement>(null);
 const fullnameRef = useRef<HTMLInputElement>(null);
 const messageRef = useRef<HTMLDivElement>(null);
 const companyRef = useRef<HTMLInputElement>(null);
 const addressRef = useRef<HTMLInputElement>(null);
 const cityRef = useRef<HTMLInputElement>(null);
 const taxidRef = useRef<HTMLInputElement>(null);
 const postalcodeRef = useRef<HTMLInputElement>(null);

 const { user, saveUser } = useContext(UserContext) as UserContextInterface;
 const [phone, setPhone] = useState('');
 const [validateClass, setValidateClass] = useState('');
 const [response, setResponse] = useState('');
 const [invalidFields, setinvalidFields] = useState(0);

 


const addClasses = (ref: RefObject<HTMLInputElement>, addClasses:string, removeClasses:string) => {
  if (ref.current !== undefined) {

  ref.current!.className = ref.current!.className.includes(' '+removeClasses) ?  ref.current!.className.replace(' '+removeClasses, ' '+addClasses) : ref.current!.className + ' '+addClasses;
  }


}
const validateInput = (ref: RefObject<HTMLInputElement>, validationType: string) => {

  if (validationType === "empty") {

    if (ref.current !== undefined && ref.current?.value === "") {
      var temp = invalidFields + 1
     //console.log("invalidfields temp", temp)
      setinvalidFields(temp)
     //console.log("invalidfields", invalidFields)
      addClasses(ref, 'is-invalid', 'is-valid')
    } else if (ref.current !== undefined && ref.current?.value !== "") {
      
      addClasses(ref, 'is-valid', 'is-invalid')
    }
  }


}



 const updateData = async (typeUpdate: string) =>{

 //console.log('type',typeUpdate)
  var postData = {}
  
  if (typeUpdate === 'personal') {

    postData = {
      email: user.email,
      password: user.password,
      phoneContact: phone,
      fullname: (fullnameRef.current!.value !== undefined) ?  fullnameRef.current!.value : ""
    };
  } else {

    validateInput(countryRef, 'empty')
    validateInput(companyRef, 'empty')
    // check if business field are filled
    validateInput(addressRef, 'empty')
    validateInput(cityRef, 'empty')
    validateInput(postalcodeRef, 'empty')
    validateInput(taxidRef, 'empty')

    postData = {
      email: user.email,
      password: user.password,
      phoneContact: phone,
      fullname: (fullnameRef.current!.value !== undefined) ?  fullnameRef.current!.value : "",
      billingData: {
        companyName: (companyRef.current!.value !== undefined) ?  companyRef.current!.value : '',
        primaryAddress: (addressRef.current!.value !== undefined) ?  addressRef.current!.value : '',
        city: (cityRef.current!.value !== undefined) ?  cityRef.current!.value : '',
        postal_code: (postalcodeRef.current!.value !== undefined) ?  postalcodeRef.current!.value : '',
        country: (countryRef.current!.value !== undefined) ? countryRef.current!.value : '',
        tax_ID: (taxidRef.current!.value !== undefined) ?  taxidRef.current!.value : '',


      }
    };
  }
 

  var checkerrorClass = document.getElementsByClassName(typeUpdate + ' is-invalid')


  if (checkerrorClass.length === 0) {
  var srvRoute = '/user/update';
  let res = await srvConn(srvRoute,postData);
 //console.log("res",res);

  if (res.success) {
    setResponse(t("profile.user_update_success"));
    messageRef.current!.className  = messageRef.current!.className.includes(' show') ?  messageRef.current!.className : messageRef.current!.className + ' show';
    messageRef.current!.className = messageRef.current!.className.includes(' alert-danger') ?  messageRef.current!.className.replace(' alert-danger', ' alert-success') : messageRef.current!.className;

    /*saveUser({
    name:(fullnameRef.current!.value !== undefined) ?  fullnameRef.current!.value : "",
    phoneContact: phone
     })*/
    await timeout(2000)
    dismissClick(null)
     

  } else {
    setResponse(t("profile.user_update_error"));
    messageRef.current!.className  = messageRef.current!.className.includes(' show') ?  messageRef.current!.className : messageRef.current!.className + ' show';
    messageRef.current!.className = messageRef.current!.className.includes(' alert-success') ?  messageRef.current!.className.replace(' alert-success', ' alert-danger') : messageRef.current!.className;

    await timeout(2000)
    dismissClick(null)
    
  }
} else {
  setResponse(t("profile.invalid_fields"));
    messageRef.current!.className  = messageRef.current!.className.includes(' show') ?  messageRef.current!.className : messageRef.current!.className + ' show';
    messageRef.current!.className = messageRef.current!.className.includes(' alert-success') ?  messageRef.current!.className.replace(' alert-success', ' alert-danger') : messageRef.current!.className;
    
    await timeout(2000)
    dismissClick(null)
}

 }

 const dismissClick = (e:any) => {
  if (messageRef.current !== undefined)
  messageRef.current!.className = messageRef.current!.className.replace(" show", '');
  
}
const retrieve_data = async() => {
  var postData = {
    email: user.email,
    password: user.password
  };


  var srvRoute = '/user/login';
  let res = await srvConn(srvRoute,postData);

  if (res.response && res.response.user_data) {
    if (fullnameRef.current) fullnameRef.current!.value = res.response.user_data.fullname

    setPhone( res.response.user_data.phoneContact)
    var temp = (res.response.user_data.billingData && res.response.user_data.billingData.country !== undefined) ? res.response.user_data.billingData.country : ""
   //console.log('temp', temp)
    setCountry(temp)
    if (res.response.user_data.billingData) {
    if (companyRef.current) companyRef.current!.value = (res.response.user_data.billingData.companyName !== undefined) ? res.response.user_data.billingData.companyName : ''
    if (addressRef.current) addressRef.current!.value = (res.response.user_data.billingData.primaryAddress !== undefined) ? res.response.user_data.billingData.primaryAddress  : ""
    if (cityRef.current) cityRef.current!.value = (res.response.user_data.billingData.city !== undefined) ? res.response.user_data.billingData.city  : ""
    if (postalcodeRef.current) postalcodeRef.current!.value = (res.response.user_data.billingData.postal_code !== undefined) ? res.response.user_data.billingData.postal_code  : ""
    if (taxidRef.current) taxidRef.current!.value = (res.response.user_data.billingData.tax_ID !== undefined) ? res.response.user_data.billingData.tax_ID  : ""
    if (countryRef.current) countryRef.current!.value = (res.response.user_data.billingData.country !== undefined) ? res.response.user_data.billingData.country  : ""

  }
   //console.log('res profile', res.response.user_data)
   //console.log("res profile", res.response.user_data.fullname);
  }


}


 useEffect(() => {

 
  if (user.isLogged && user.name === undefined) {
   //console.log('if isLogged', user.isLogged);
    retrieve_data()
  }
  if (user.email) emailRef.current!.value = user!.email

  if (user.name) fullnameRef.current!.value = user.name

 //console.log("country", country)
 //console.log("invalidf", invalidFields)


}, [user, emailRef, country, invalidFields]);


 
  return(
    <div className="container-fluid w-100 scrollarea">
    <div className="form-head mb-sm-5 mb-3 d-flex flex-wrap align-items-center">
      <div className="container">
      <h2 className="font-w600 title mb-2 me-auto ">{t("profile.title")}</h2>

      
    

 
  <div className='row w-auto'>
  <div ref={messageRef} className="alert alert-success alert-dismissible fade" role="alert">
              <button type="button" className="close" data-dismiss="alert" onClick={(e) => dismissClick(e)} aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
              <strong> {response}</strong> 
            </div>
  <form action="#" autoComplete="on" className="personal">
  <div className="row">
    <div className="col-sm">
  <div className="form-group">
    <label htmlFor="fullname">{t("profile.name")}</label>
    <input ref={fullnameRef} type="text" className="form-control" id="fullname" placeholder="Mark Twain" />
  </div>
  </div>
  
 
    <div className="col-sm">
  <div className="form-group">
    <label htmlFor="email">{t("profile.email")}</label>
    <input ref= {emailRef} type="text" className="form-control" id="email" placeholder="example@youremail.com" disabled />
  </div>
  </div>
  <div className="form-group">
    <label htmlFor="email">{t("profile.phone")}</label>
  
    <PhoneInput
        enableSearch={true}
        country={'uk'}
        value={phone}
        onChange={(phone) => {
          
          setPhone(phone)

        }}
        inputProps={{
          name: 'phone',
          required: true,
          autoFocus: true,
          className: `form-control ${validateClass}`
        }}
        
      />
    
  </div>
  <button type="button" className="btn btn-lg btn-block btn-primary" onClick={ (e) => {e.preventDefault()
    updateData('personal')} }>Update Info</button>
   
  </div>
 </form>
   
 <div className="row w-auto mt-5">
  <h5>Organization settings</h5>
  <form action="#" autoComplete="on" className="business">
  <div className="row w-auto">
  <div className="form-group">
    <label htmlFor="name">{t('profile.billing_company_name')}</label>
    <input ref={companyRef} type="text" className="form-control" id="company-name" placeholder="Acme LTD" />
  </div>
  <div className="form-group">
    <label htmlFor="name">{t('profile.billing_country')}</label>
   
   
    <input ref={countryRef} className="form-control" list="datalistOptions" id="countryDataList" placeholder="Type to search..."/>
<datalist id="datalistOptions">
{ countries.map((data,index) => (<><option key={index} value={data.name} /> </>))}

</datalist>
   
  </div>
  <div className="form-group">
    <label htmlFor="name">{t('profile.billing_address')}</label>
    <input ref={addressRef} type="text" className="form-control" id="address" placeholder="Address line" />
  </div>
  <div className="row w-auto">
    <div className="col-sm">
  <div className="form-group">
    <label htmlFor="name">{t('profile.billing_city')}</label>
    <input ref={cityRef} type="text" className="form-control" id="city" placeholder="London" />
  </div>
  </div>
  
 
    <div className="col-sm">
  <div className="form-group">
    <label htmlFor="email">{t('profile.billing_zip')}</label>
    <input ref={postalcodeRef} type="text" className="form-control" id="postal_code" placeholder="WC2H 9JQ" />
  </div>
  </div>
  </div>
  <h5>{t('profile.billing_business_tax_id')}</h5>
  <div className="form-group">
    <label htmlFor="tax_id">{t('profile.billing_tax_id')}</label>
    <input ref={taxidRef} type="text" className="form-control" id="tax_id" placeholder={t('profile.billing_tax_id')} />
  </div>
  <button type="button" className="btn btn-lg btn-block btn-primary" onClick={() => updateData('business')}>Save</button>
  </div>

  

  
</form>
</div>
</div>
</div>
  </div>      
    </div>
   

  
  )
}

export default Profile


