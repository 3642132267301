import { Link, useParams } from "react-router-dom";
import Language from "../components/Language";
import { t } from "i18next";
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import ChartModuleMore from 'highcharts/highcharts-more.js';
import SolidGauge from 'highcharts/modules/solid-gauge';
import { useEffect, useRef, useState } from "react";
import srvConn from "../helpers/srvConn";

ChartModuleMore(Highcharts);
SolidGauge(Highcharts);

var tickPositions = [10, 220, 260, 485, 520, 740, 760, 990];
  var values = [0, 250, 251, 500, 501, 750, 751, 1000];
  var angles = tickPositions.map(function (value) {
    return getAngle(value);
  });

  var tickPositionsDesktop = [10, 230, 270, 480, 520, 740, 770, 980];
  var values = [0, 250, 251, 500, 501, 750, 751, 1000];
  var angles = tickPositionsDesktop.map(function (value) {
    return getAngle(value);
  });

  function getAngle(value: number) {
    var angle = value * 135 / 500 - 45;
    return angle > 90 ? -180 + angle : angle;
  }
  var labels = (function() {
    var labels:any = {};
    tickPositions.forEach(function(value, i) {
      labels[value] = {text: values[i]};
    });
    tickPositionsDesktop.forEach(function(value, i) {
      labels[value] = {text: values[i]};
    });
    return labels;
  })();
const options = { title: {
  useHTML: true,
  text: '',
  y: 250
  
},
  
  chart: {  
    
      type: 'solidgauge',
      height: 300,
      width: 300,
      //marginTop: 10,
      //marginBottom: 0,
      //style: { margin: 'auto' },  
    events: {
      load: function() {
        var labels = document.getElementsByClassName('highcharts-yaxis-labels')[1].children;
        Array.prototype.forEach.call(labels, function(label, i) {
          var x = label.getAttribute('x'),
              y = label.getAttribute('y'),
              transform = label.getAttribute('transform');
            
          label.setAttribute('transform', transform + ' rotate(' + angles[i] + ' ' + x + ' ' + y + ')');
        });
      }
      
    }
  },
  pane: [{
    startAngle: -135,
    endAngle: 135,
    background: [{
      innerRadius: '50%',
      outerRadius: '100%',
      shape: 'arc'
    }]
  }, {
    size: '100%',
    background: [],
    startAngle: -135,
    endAngle: 135
  }],
  yAxis: [{
    lineWidth: 5,
    lineColor: 'white',
    tickLength:20,
    tickWidth: 3,
    minorTickLength: 0,
    tickColor: 'white',
    tickPositions: [250.5, 500.5, 750.5],
    labels: {
      enabled: false
    },
    min: 0,
    max: 1000,
    zIndex: 6,
    stops: [
      [0.2505, 'red'],
      [0.5005, 'orange'],
      [0.7505, 'yellow'],
      [1, 'green']
    ]
  }, {
    linkedTo: 0,
    lineWidth: 0,
    minorTickInterval: null,
    tickLength: 0,
    tickPositions: tickPositions,
    zIndex: 6,
    labels: {
      style: {
        color: '#333'
      },
      formatter:  function(): any {
        //console.log('labels', this)
       // return labels[value].text;
      }
    }
  }, {
    pane: 1,
    lineColor: 'white',
  lineWidth: 30,
    zIndex: 6
  }],
  series: [{
    type: 'solidgauge',
    data: [1000, 750, 500, 250],
    radius: '100%',
    innerRadius: '80%',
    dataLabels: {
      enabled: false
    },
    enableMouseTracking: false,
    borderWidth: 0
  }, {
    enableMouseTracking: false,
    type: 'gauge',
    data: [350],
    pivot: {
      radius: 60,
      backgroundColor: 'green'
    },
    dial: {
      radius: "100%",
      backgroundColor: "#454545",
      baseWidth: 30,
      topWidth: 1,
      baseLength: "1%",
      rearLength: "5%"
    },
    dataLabels: {
      zIndex: 6,
      borderWidth: 0,
      style: {
        fontSize: '30px'
      },
      y: -25
    }
  }]
};


const Verify = (props:any) => {
  const [loading, setLoading] = useState(false);
  const params = useParams();
  const chartComponentRef = useRef(null);
  const [width, setWidth] = useState(window.innerWidth);
  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
   //console.log("width",width)
}
const isMobile = width <= 768;

 //console.log(params); // 👉️ {userId: '4200'}
 
    
    useEffect(() => {
      const retrieve_data = async (id:string, idobj:string) => {
        var srvRoute = '/revue/file/evaluation/';
             
       
        var postData = {
          file: idobj+'.png',
        };
    
       
          let res = await srvConn(srvRoute,postData);
       //console.log("res",res);

        if (res.success) { var eval_num = Number(res.response['evaluated_file']['score'])
         //console.log('eval_num', eval_num)
        
      
          options.series[1].data = [eval_num]

          if (eval_num >= 0 && eval_num < 251) {
            options.series[1].pivot!.backgroundColor = 'red'
        
          } else if (eval_num > 250 && eval_num < 501) {
            options.series[1].pivot!.backgroundColor = 'orange'
          }else if (eval_num > 500 && eval_num < 701) {
            options.series[1].pivot!.backgroundColor = 'yellow'
          } else if (eval_num > 750) {
            options.series[1].pivot!.backgroundColor = 'green'
          }
         
          setLoading(true)
        } else {

          }
       
       
       
    
      }
      
     
      //props.enablePreloader(true);
     
        if (params.id && params.idobj && loading === false) 
          retrieve_data(params.id, params.idobj)
          
    
    
      
        
    }, [params, options]);


  return (
    <section className="ftco-section" style={{backgroundImage:  'url("/images/bgback.jpg")', backgroundPosition:'center center', backgroundRepeat: "no-repeat", backgroundSize:"cover",height:"100vh"}}>
		<div className="container">

			<div className="row justify-content-center">
				<div className="col-md-12 col-lg-10">
					<div className="wrap d-md-flex">
						<div className="img" style={{backgroundImage:  'url("'+process.env.REACT_APP_DOMAIN+params.id+'/'+params.idobj+'.png")', backgroundPosition:'bottom right', height:"80vh"}}>
			      </div>
						<div className="login-wrap p-4 p-md-5">
			      	<div className="d-flex">
			      		<div className="w-100">
							<div><img src='/images/logo.png' alt="logo" width="200" /></div>
							<h4 className="mb-4">Visual Impact Index</h4><p>&nbsp;</p>
			      			<h3 className="mb-4">{t("verify.title")}</h3>
                  {loading === true  ? (<><HighchartsReact
      highcharts={Highcharts}
      options={options}
      ref={chartComponentRef}
      {...props} />
      <div className="d-flex justify-content-center align-items-center" style={{width: 340}}>
  <img src="/images/legend.png" alt="Legend" style={{width: 340}} />
</div>
      </>): (<></>) }
                  
			      		</div>
        
								</div>
               
						
		        </div>
            </div>
		      </div>
				</div>
        <Language />
			</div>
     
	</section>
  )
}

export default Verify