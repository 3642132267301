import axios, { AxiosResponse } from 'axios';
import { Buffer } from 'buffer';

const default_headers = {
  'Content-Type': 'application/json',
  "Access-Control-Allow-Origin": "*",
  //Authorization: 'Basic ' +  Buffer.from(
    //  `${process.env.REACT_APP_USER_API}:${process.env.REACT_APP_PWD_API}`
   // ).toString('base64'),
};

const srvConn = async (srvRoute: string, postData: any, headers:any = default_headers) => {
//console.log('postData', postData)
  
  

  if (postData == null) {
    try {
      const res: any = await axios.get(
        `${process.env.REACT_APP_BACKEND_SERVER}${srvRoute}`,
        
        { headers: headers }
      );
      

      if (res && res.data !== null && res.data.length > 0) {
        
      //console.log('res', res);
        return { success: true, response: res.data};
      } else {
        return {
          success: false,
          error: res.response ? res.response!.data.error : 'code not exist',
        };
      }
    } catch (err: any) {
     //console.log('err', err);
      return {
        success: false,
        error: err.response ? err.response!.data.error : 'code not exist',
      };
    }
  } else {
    try {
      const res: any = await axios.post(
        `${process.env.REACT_APP_BACKEND_SERVER}${srvRoute}`,
        postData,
        { headers: headers }
      );

      if (res) {
       //console.log('res', res);
       //console.log('res.data.success', res.data.success)
       //console.log("message---->", res.data.message)
      if (res.data.success !== undefined) {
        return { success: true, response:res.data };

      } else {
        return { success: false, error: res.data.error };
      }
        
      } else {
        return { success: false, error: res.data.error };
      }
    } catch (err: any) {
    //console.log('err', err);
      var error;
      if (err.code === "ERR_NETWORK" && err.response === undefined) {
        error = {"detail": "Server error"}
      } else if (err.code === "ERR_BAD_RESPONSE" && err.response === undefined) {
        error = {"detail": "Server error"}
      } else {
        error = {"detail": err.response!.data.detail}
      }
      
      //axios fails
      return { success: false, error: error, server: "error" };
    }
  }
};

export default srvConn;
