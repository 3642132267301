import React from "react";
import { IUser } from "../@types/user";

export interface Props {
  user: IUser;
}
const PasswordRecovery = (props: Props) => {
return (
  <>Password recovery</>
)
}

export default PasswordRecovery;