import React, { useState, useEffect, useRef, useContext } from 'react';
import { UserContext } from '../context/UserContext';
import { IUser } from '../@types/user';
import srvConn from '../helpers/srvConn';
import { t } from 'i18next';
import { Link, useNavigate } from 'react-router-dom';
import { timeout } from '../helpers/usefulFunction';
export interface Props {
    user: IUser;
  }

const ActivationCode = (props: Props) => {
    const {user, saveUser} = useContext(UserContext);
    const [code, setCode] = useState('');
    const [pasted, setPasted] = useState('');
    const firstRef = useRef<HTMLInputElement>(null);
    const secondRef = useRef<HTMLInputElement>(null);
    const thirdRef = useRef<HTMLInputElement>(null);
    const fourthRef = useRef<HTMLInputElement>(null);
    const fifthRef = useRef<HTMLInputElement>(null);
    const sixthRef = useRef<HTMLInputElement>(null);
    const seventhRef = useRef<HTMLInputElement>(null);
    const eighthRef = useRef<HTMLInputElement>(null);
    const ninthRef = useRef<HTMLInputElement>(null);
    const tenthRef = useRef<HTMLInputElement>(null);
    const message = useRef<HTMLParagraphElement>(null);
    const navigate = useNavigate();

    useEffect(() => {
       //console.log('=', message.current?.innerText)
    
      }, [message]);

    const activateUser = async (user: IUser, act_code:string) => {
        var srvRoute = '/user/activate_user';
        var postData;

   //console.log('code', act_code) 
   //console.log('name', user.name) 
    if (user.name === undefined || user.name === "" ) {
      postData = {
        email: user.email,
        password: user.password,
        stateAccount:[{activationCode: act_code}]
      };
    } else {
      postData = {
        email: user.email,
        password: user.password,
        fullname: user.name,
        language: user.language,
        stateAccount:[{activationCode: act_code}]
      };
    }
    

    let res = await srvConn(srvRoute, postData);
   //console.log("res --->", res)
    if (res.success) {
       //console.log("postData",postData)
        //
        message.current!.innerText =  t("form_signup.activation_response_success")
        message.current!.className = "text-success"
        
        await timeout(2000)
        
        navigate("/");
        saveUser({name: postData['fullname'], email:postData['email'], isLogged: true, password: postData['password'], isActive:true, language: postData['language'] })
      
            
      
    } else {
       //console.log("error")
        message.current!.innerText = t("form_signup.activation_response_error") 
        message.current!.className = "text-danger"
        
      }
    }
    const handlePaste =(event: any) => {
        event.preventDefault();
        var paste = event.clipboardData.getData('text')
       //console.log("paste", paste)
        const array_paste = paste.split('');
       //console.log("array", array_paste);
        if (array_paste.length === 10) {
              firstRef.current!.value = array_paste[0] 
              secondRef.current!.value = array_paste[1] 
              thirdRef.current!.value = array_paste[2] 
              fourthRef.current!.value = array_paste[3] 
              fifthRef.current!.value = array_paste[4] 
              sixthRef.current!.value = array_paste[5] 
              seventhRef.current!.value = array_paste[6] 
              eighthRef.current!.value = array_paste[7] 
              ninthRef.current!.value = array_paste[8] 
              tenthRef.current!.value = array_paste[9] 

              // send request to server
             //console.log("activationcode user", user)
              setCode(paste)
             
              let res = activateUser(user, array_paste.join(''))
              //setRes(res);
             //console.log("res", res)
             //console.log("message", message)
              //saveUser({isActive:true, isLogged: true})


        }


        //check on api.revue 



    }

    const handleChange = (event:any) => {
      event.preventDefault();
      if (firstRef.current?.value !== "" && secondRef.current?.value !== "" && thirdRef.current?.value !== "" && fourthRef.current?.value !== "" && fifthRef.current?.value && sixthRef.current?.value !== "" && seventhRef.current?.value !== "" && eighthRef.current?.value !== "" &&ninthRef.current?.value !== "" && tenthRef.current?.value !== "" ) 
        {
          var value = firstRef.current!.value + secondRef.current!.value + thirdRef.current?.value + fourthRef.current?.value + fifthRef.current?.value + sixthRef.current?.value + seventhRef.current?.value + eighthRef.current?.value + ninthRef.current?.value + tenthRef.current?.value
          setCode(value)
          console.log('value', value)
          let res = activateUser(user, value)

        }


    }

    return (
        <div className="mb-6 text-center">
               <p ref={message} className="text-danger"></p>
  <div id="otp" className="d-flex justify-center">
    <input className="m-2 text-center form-control form-control-solid rounded focus:border-blue-400 focus:shadow-outline" type="text" ref={firstRef} onPaste={(e) => handlePaste(e)} maxLength={1} />
    <input className="m-2 text-center form-control form-control-solid rounded focus:border-blue-400 focus:shadow-outline" type="text" ref={secondRef} maxLength={1} />
    <input className="m-2 text-center form-control form-control-solid rounded focus:border-blue-400 focus:shadow-outline" type="text" ref={thirdRef} maxLength={1} />
    <input className="m-2 text-center form-control form-control-solid rounded focus:border-blue-400 focus:shadow-outline" type="text" ref={fourthRef} maxLength={1} />
    <input className="m-2 text-center form-control form-control-solid rounded focus:border-blue-400 focus:shadow-outline" type="text" ref={fifthRef} maxLength={1} />
    <input className="m-2 text-center form-control form-control-solid rounded focus:border-blue-400 focus:shadow-outline" type="text" ref={sixthRef} maxLength={1} />
    <input className="m-2 text-center form-control form-control-solid rounded focus:border-blue-400 focus:shadow-outline" type="text" ref={seventhRef} maxLength={1} />
    <input className="m-2 text-center form-control form-control-solid rounded focus:border-blue-400 focus:shadow-outline" type="text" ref={eighthRef} maxLength={1} />
    <input className="m-2 text-center form-control form-control-solid rounded focus:border-blue-400 focus:shadow-outline" type="text" ref={ninthRef} maxLength={1} />
    <input className="m-2 text-center form-control form-control-solid rounded focus:border-blue-400 focus:shadow-outline" type="text" ref={tenthRef} maxLength={1} onChange={(e) => handleChange(e)} />
  </div>
</div>
    )
}

export default ActivationCode