import * as React from 'react';
import { UserContextInterface, IUser } from '../@types/user';
import {AES, enc}from 'crypto-js';

export const UserContext = React.createContext<UserContextInterface>(
  {} as UserContextInterface
);

type IProps = {
  children: React.ReactNode;
};

const UserProvider: React.FC<IProps> = ({ children }) => {
  var userSaved = {
    name: '',
    email: '',
    isLogged: false,
    mobile: '',
  }
  if (localStorage.getItem('app.revue') !== null){
    var secretKey = process.env.REACT_APP_CRYPTO_SECRET
    //console.log("secret", secretKey)
    const encryptedData = localStorage.getItem('app.revue');
    const decryptedData = AES.decrypt(encryptedData!, secretKey!);
    
    userSaved = JSON.parse(decryptedData.toString(enc.Utf8));
    //console.log("usersaved", userSaved)
  //console.log("user", localStorage.getItem('app.revue'))
  }
  
  
  const [user, setuser] = React.useState<IUser>(userSaved);

  const saveUser = (user: IUser) => {
    var secretKey = process.env.REACT_APP_CRYPTO_SECRET
    //console.log("secret", secretKey)
    //console.log('context', user);
    setuser(user);
    const encryptedData = AES.encrypt(JSON.stringify(user), secretKey!);
    //console.log("data encrypted",encryptedData);
    localStorage.setItem('app.revue', encryptedData.toString());
  };

  const value = React.useMemo(  
    () => ({
      user,
      saveUser,
    }),
    [user]
  );

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export default UserProvider;
