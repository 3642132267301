import { t } from "i18next";

const validationInput = (input: string, type: string) => {
  switch (type) {
    case 'email':
      const emailRegex =
        /[a-z0-9!#$%&'*+/=?^_{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
      //email not valid
      if (!input.match(emailRegex)) {
        return {
          success: false,
          message: 'Email not valid, please enter a valid email address',
          class: 'is-invalid',
        };
      } else {
        return { success: true, message: 'Email correct', class: 'is-valid' };
      }
    case 'minchars':
      if (input !== null) {
      var inputLen = input.length;
      //console.log('inputLen', inputLen);
      if (inputLen <= 3) {
        return {
          success: false,
          message: t("validate.minChars", {minChars:"4"}),
          class: 'is-invalid',
        };
      } else {
        return { success: true, message: 'Name correct', class: 'is-valid' };
      }
    }

  }
};

export default validationInput;
