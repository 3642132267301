import { t } from "i18next"

const Settings = () => {

  return(
    <div className="container-fluid w-100 scrollarea">
    <div className="form-head mb-sm-5 mb-3 d-flex flex-wrap align-items-center">
      <div className="container">
      <h2 className="font-w600 title mb-2 me-auto ">{t("menu_user.title_2")}</h2>

      
    
    <div className="row container-fluid">
 
  <div className='row'>
  
 
       
          

</div>
</div>
  </div>      
    </div>
   

  </div>
  )
}

export default Settings