import axios from "axios";
import fileDownload from 'js-file-download'

function timeout(delay: number) {
    return new Promise( res => setTimeout(res, delay) );
}

function getFileExtension(filename:string) {
    const extension = filename.split('.').pop();
    return extension;
  }

  const handleDownload = (e:any, url:string, filename:string) => {
    e.preventDefault()
    
    axios.get(url, {
      responseType: 'arraybuffer',
      headers: {
        'Content-Type': 'application/json'
      }
    })
    .then((res) => {
      fileDownload(res.data, filename)
    })
  }

export {timeout, getFileExtension, handleDownload}