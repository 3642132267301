import React, {useCallback} from 'react'
import { IUser } from "../@types/user"
import Dropzone, { useDropzone } from 'react-dropzone'
import Basic from './dragndropFiles/Basic'

export interface Props {
  user: IUser
}


const EvaluatePhoto = (props: Props) => {

  //console.log("user", props.user)
  return (
    <div className="container-fluid w-100 scrollarea">
    <div className="form-head mb-sm-5 mb-3 d-flex flex-wrap align-items-center">
      
      <h2 className="font-w600 title mb-2 me-auto ">Evaluate your photo</h2>
      </div> 
    
    <div className="row">
 

 <Basic user={props.user} />

</div>
     
    </div>
   

  )
}

export default EvaluatePhoto