import Home from './pages/Home';
import { IUser } from './@types/user';
import React from 'react';
import Login from './pages/Login';
import Register from './pages/Register';
import PasswordRecovery from './pages/PasswordRecovery';
import ActivationClient from './pages/ActivationClient';
import Logout from './pages/Logout';
import Verify from './pages/Verify';

const routes = [
  {
    path: '/',
    component: (user: IUser) => (
      <Home user={user} />
    ),
  },
  { path: '/login', component: (user: IUser) => <Login user={user} /> },
  { path: '/register', component: (user: IUser) => <Register user={user} /> },
  {
    path: '/password-recovery',
    component: (user: IUser) => <PasswordRecovery user={user} />,
  },
  {
    path: '/activation-client',
    component: (user: IUser) => <ActivationClient user={user} />,
  },
  {
    path: '/logout',
    component: (user: IUser) => <Logout user={user} />,
  },
  {
    path: '/verify/:id/:idobj',
    component: (user: IUser) => <Verify user={user} />,
  },
  /* { path: '/msb-teams', component: (sessionID) => (<MSBTeams sessionID={sessionID} />)},
  { path: '/claim', component: (sessionID) => (<Claim sessionID={sessionID}  />)},
  { path: '/claim-public', component: (sessionID) => (<ClaimPublic sessionID={sessionID}  />)},
  { path: '/admin/goals', component: (sessionID) => (<GoalsCheck sessionID={sessionID}  />)},
  { path: '/admin/parrainage', component: (sessionID) => (<ParrainageCheck sessionID={sessionID}  />)},*/
];

export default routes;
