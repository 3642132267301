import i18n from "i18next";
import LanguageDetector from 'i18next-browser-languagedetector'
import i18nBackend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";
import translation_en from './en/translation.json';
import translation_it from './it/translation.json';
import translation_fr from './fr/translation.json';


i18n
  .use(i18nBackend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    detection: {
      lookupLocalStorage: 'language'
    },
    fallbackLng: "en",
    debug: true,
    //options: options,
    //lng: getCurrentLang(),
    interpolation: {
      escapeValue: false,
    },
    resources: {
      en: { translation: translation_en },
      it: { translation: translation_it },
      fr: { translation: translation_fr },
    },

  });

export default i18n;


