import React, { useContext, useEffect, useRef, useState } from 'react';
import { IUser } from '../@types/user';
import { useLocation, useParams } from 'react-router';
import srvConn from '../helpers/srvConn';
import { useSearchParams } from 'react-router-dom';
import {UserContext} from '../context/UserContext';
import ActivationCode from '../components/ActivationCode';
import Language from '../components/Language';
import { t } from 'i18next';

export interface Props {
  user: IUser;
}

const ActivationClient = (props: Props) => {
  const {user, saveUser} = useContext(UserContext);
  const { id } = useParams();
 
  const [searchParams, setSearchParams] = useSearchParams();
  const token = searchParams.get('ac');
 //console.log('token ', token);

  const search = useLocation().search;
  const ac = new URLSearchParams(search).get('ac');
 //console.log('ac ', ac);

  

  useEffect(() => {

  }, []);

  return (
    <section className="ftco-section">
		<div className="container">

			<div className="row justify-content-center">
				<div className="col-md-12 col-lg-10">
					<div className="wrap d-md-flex">
						<div className="img" style={{backgroundImage:  'url("./images/bg-1.jpg")',}}>
			      </div>
						<div className="login-wrap p-4 p-md-5">
			      	<div className="d-flex">
			      		<div className="w-100">
							<div><img src='images/logo.png' alt="logo" width="200" /></div>
                    <h4 className="text-center mb-4">Account</h4>
                    {t("form_signup.activation_lb")}
                    <ActivationCode user={user} />
                  </div>
                </div>
              </div>
              </div>
		      </div>
				</div>
        <Language />
			</div>
	</section>
  );
};

export default ActivationClient;
