import React, {useState, useEffect, useRef} from 'react';
import {useDropzone} from 'react-dropzone';
import axios, { AxiosResponse } from 'axios';
import srvConn from '../../helpers/srvConn';
import {getFileExtension} from '../../helpers/usefulFunction'
import { t } from "i18next"
import { timeout } from '../../helpers/usefulFunction';
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import * as ChartModuleMore from 'highcharts/highcharts-more.js';
import SolidGauge from 'highcharts/modules/solid-gauge';
import fileDownload from 'js-file-download'

const {v4 : uuidv4} = require('uuid')

const thumbsContainer = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  marginTop: 16
};

const thumb = {
  display: 'inline-flex',
  borderRadius: 2,
  border: '1px solid #eaeaea',
  marginBottom: 8,
  marginRight: 8,
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: 'border-box'
};

const thumbInner = {
  display: 'flex',
  minWidth: 0,
  overflow: 'hidden'
};

const img = {
  display: 'block',
  width: 'auto',
  height: '100%'
};

ChartModuleMore(Highcharts);
SolidGauge(Highcharts);

var tickPositions = [10, 220, 260, 485, 520, 740, 760, 990];
  var values = [0, 250, 251, 500, 501, 750, 751, 1000];
  var angles = tickPositions.map(function (value) {
    return getAngle(value);
  });

  var tickPositionsDesktop = [10, 230, 270, 480, 520, 740, 770, 980];
  var values = [0, 250, 251, 500, 501, 750, 751, 1000];
  var angles = tickPositionsDesktop.map(function (value) {
    return getAngle(value);
  });

  function getAngle(value) {
    var angle = value * 135 / 500 - 45;
    return angle > 90 ? -180 + angle : angle;
  }
  var labels = (function() {
    var labels = {};
    tickPositions.forEach(function(value, i) {
      labels[value] = {text: values[i]};
    });
    tickPositionsDesktop.forEach(function(value, i) {
      labels[value] = {text: values[i]};
    });
    return labels;
  })();
const options = { title: {
  useHTML: true,
  text: '<img src="./images/legend.png" height="40">',
  y: 250
  
},
  
  chart: {  
    
      type: 'solidgauge',
      height: 300,
      width: 300,
      //marginTop: 10,
      //marginBottom: 0,
      //style: { margin: 'auto' },  
    events: {
      load: function() {
        var labels = document.getElementsByClassName('highcharts-yaxis-labels')[1].children;
        Array.prototype.forEach.call(labels, function(label, i) {
          var x = label.getAttribute('x'),
              y = label.getAttribute('y'),
              transform = label.getAttribute('transform');
            
          label.setAttribute('transform', transform + ' rotate(' + angles[i] + ' ' + x + ' ' + y + ')');
        });
      }
      
    }
  },
  pane: [{
    startAngle: -135,
    endAngle: 135,
    background: [{
      innerRadius: '50%',
      outerRadius: '100%',
      shape: 'arc'
    }]
  }, {
    size: '100%',
    background: [],
    startAngle: -135,
    endAngle: 135
  }],
  yAxis: [{
    lineWidth: 5,
    lineColor: 'white',
    tickLength:20,
    tickWidth: 3,
    minorTickLength: 0,
    tickColor: 'white',
    tickPositions: [250.5, 500.5, 750.5],
    labels: {
      enabled: false
    },
    min: 0,
    max: 1000,
    zIndex: 6,
    stops: [
      [0.2505, 'red'],
      [0.5005, 'orange'],
      [0.7505, 'yellow'],
      [1, 'green']
    ]
  }, {
    linkedTo: 0,
    lineWidth: 0,
    minorTickInterval: null,
    tickLength: 0,
    tickPositions: tickPositions,
    zIndex: 6,
    labels: {
      style: {
        color: '#333'
      },
      formatter: function() {
        return labels[this.value].text;
      }
    }
  }, {
    pane: 1,
    lineColor: 'white',
  lineWidth: 30,
    zIndex: 6
  }],
  series: [{
    type: 'solidgauge',
    data: [1000, 750, 500, 250],
    radius: '100%',
    innerRadius: '80%',
    dataLabels: {
      enabled: false
    },
    enableMouseTracking: false,
    borderWidth: 0
  }, {
    enableMouseTracking: false,
    type: 'gauge',
    data: [350],
    pivot: {
      radius: 60,
      backgroundColor: 'green'
    },
    dial: {
      radius: "100%",
      backgroundColor: "#454545",
      baseWidth: 30,
      topWidth: 1,
      baseLength: "1%",
      rearLength: "5%"
    },
    dataLabels: {
      zIndex: 6,
      borderWidth: 0,
      style: {
        fontSize: '30px'
      },
      y: -25
    }
  }]
};

const options_desktop = { title: {
  useHTML: true,
  text: '<img src="./images/legend.png" height="40">',
  y: 500
  
},
  
  chart: {  
    
      type: 'solidgauge',
      height: 600,
      width: 600,
      //marginTop: 10,
      //marginBottom: 0,
      //style: { margin: 'auto' },  
    events: {
      load: function() {
        var labels = document.getElementsByClassName('highcharts-yaxis-labels')[1].children;
        Array.prototype.forEach.call(labels, function(label, i) {
          var x = label.getAttribute('x'),
              y = label.getAttribute('y'),
              transform = label.getAttribute('transform');
              
          label.setAttribute('transform', transform + ' rotate(' + angles[i] + ' ' + x + ' ' + y + ')');
        });
      }
      
    }
  },
  pane: [{
    startAngle: -135,
    endAngle: 135,
    background: [{
      innerRadius: '80%',
      outerRadius: '100%',
      shape: 'arc'
    }]
  }, {
    size: '67%',
    background: [],
    startAngle: -135,
    endAngle: 135
  }],
  yAxis: [{
    lineWidth: 20,
    lineColor: 'white',
    tickLength: 60,
    tickWidth: 10,
    minorTickLength: 0,
    tickColor: 'white',
    tickPositions: [250.5, 500.5, 750.5],
    labels: {
      enabled: false
    },
    min: 0,
    max: 1000,
    zIndex: 6,
    stops: [
      [0.2505, 'red'],
      [0.5005, 'orange'],
      [0.7505, 'yellow'],
      [1, 'green']
    ]
  }, {
    linkedTo: 0,
    lineWidth: 0,
    minorTickInterval: null,
    tickLength: 0,
    tickPositions: tickPositionsDesktop,
    zIndex: 6,
    labels: {
      style: {
        color: '#333'
      },
      formatter: function() {
        return labels[this.value].text;
      }
    }
  }, {
    pane: 1,
    lineColor: 'white',
  lineWidth: 10,
    zIndex: 6
  }],
  series: [{
    type: 'solidgauge',
    data: [1000, 750, 500, 250],
    radius: '100%',
    innerRadius: '80%',
    dataLabels: {
      enabled: false
    },
    enableMouseTracking: false,
    borderWidth: 0
  }, {
    enableMouseTracking: false,
    type: 'gauge',
    data: [350],
    pivot: {
      radius: 150,
      backgroundColor: 'green'
    },
    dial: {
      radius: "100%",
      backgroundColor: "white",
      baseWidth: 140,
      topWidth: 1,
      baseLength: "1%",
      rearLength: "5%"
    },
    dataLabels: {
      zIndex: 6,
      borderWidth: 0,
      style: {
        fontSize: '100px'
      },
      y: -75
    }
  }]
};


function Basic  (props) {
  const [loading, setLoading] = useState(false)
  const msg = useRef(null);
  const resultCard = useRef(null);
  const [fileImg, setFileImg] = useState(null);
  const chartComponentRef = useRef(null);
  const [title, setTitle] = useState('')
  const [message, setMessage] = useState('')
  const files = useRef([]);
  const [width, setWidth] = useState(window.innerWidth);
  const {getRootProps, getInputProps} = useDropzone({
    accept: {
      'image/jpeg': [],
      'image/png': []
    },
    onDrop: acceptedFiles => {
      // setFiles(acceptedFiles.map(file => 
      //   Object.assign(file, {
      //   preview: URL.createObjectURL(file)
      
      setFileImg(null)
      
     ////console.log("loading", true)
      files.current = acceptedFiles.map(file => 
          Object.assign(file, {
          preview: URL.createObjectURL(file)}))

      
}


});



  const thumbs = files.current.map(file => (
    <div style={thumb} key={file.name}>
      <div style={thumbInner}>
        <img
          src={file.preview}
          style={img}
          // Revoke data uri after image is loaded
         // onLoad={() => { URL.revokeObjectURL(file.preview) }}
        />
      </div>
    </div>
  ));



  const dismissClick = async(e) => {  
    if (msg && msg.current) {
    msg.current.className = msg.current.className.replace(" show", ' d-none');   
    await timeout(800)
   //msg.current.className = msg.current.className + " d-none"
    }
    
  }
 const freePackageAlert = async() => {
  
      setTitle( t('eval_photo.error_1file_max_title'))
     
      setMessage(t('eval_photo.error_1file_max_message'))
      if (msg && msg.current) {
      
      msg.current.className = msg.current.className.replace(" d-none", " show")
      msg.current.className = msg.current.className.replace(" alert-success", " alert-danger")
      }
      await timeout(5000)
      //dismissClick(null)

 }

   const alertDiv = async(res) => {

    //console.log("loading", loading)
    if (res.success && msg && msg.current) {

    ////console.log('success', res.response['revue_file'])
    
        
        setTitle( t('eval_photo.success_response_title'))
        setMessage(t('eval_photo.success_response_message'))
        msg.current.className = msg.current.className.replace(" d-none", " show")
        msg.current.className = msg.current.className.replace(" alert-danger", " alert-success")
  
        
        setFileImg( res.response['revue_file'])
        var eval_num = Number(res.response['score'])
        //console.log('eval_num', eval_num)

       
       
       //console.log("background", options.series[1].pivot.backgroundColor)

        if (eval_num > 0 && eval_num < 251) {
         //console.log("red")
          options.series[1].pivot.backgroundColor = 'red'
      
        } else if (eval_num > 250 && eval_num < 501) {
        
          options.series[1].pivot.backgroundColor = 'orange'
         //console.log("orange")
        }else if (eval_num > 500 && eval_num < 701) {
         //console.log("yellow")
          options.series[1].pivot.backgroundColor = 'yellow'
        } else if (eval_num > 750) {
         //console.log("green")
          options.series[1].pivot.backgroundColor = 'green'
        }

       //console.log("background", options.series[1].pivot.backgroundColor)

       

        options.series[1].data = [eval_num]
        options_desktop.series[1].data = [eval_num]
       //console.log("eval_num", eval_num)
        setLoading(false)
        await timeout(300)
        var pivot = document.getElementsByClassName("highcharts-pivot")[0]
       //console.log(pivot)
        pivot.setAttribute('fill', options.series[1].pivot.backgroundColor)
        //await timeout(3000)
        //dismissClick(null)
   
     
      
    } else if (res.error && msg && msg.current){
     //console.log("---->", res['error']['detail'])
      var error = res['error']['detail']
      setTitle( t('eval_photo.error_response_title'))
      if (error.includes('width or height')) {
        setMessage(t('eval_photo.error_412_response_message'))
      } else if (error.includes('too large')) {
        setMessage(t('eval_photo.error_413_response_message'))
      } else {
        setMessage(t('eval_photo.error_response_message'))
      }
      
      msg.current.className = msg.current.className.replace(" d-none", " show")
      msg.current.className = msg.current.className.replace(" alert-success", " alert-danger")
      setLoading(false)
      //await timeout(5000)
      //dismissClick(null)
    }

  }

  const handleDownload = (e, url, filename) => {
    e.preventDefault()
    
    axios.get(url, {
      responseType: 'arraybuffer',
      headers: {
        'Content-Type': 'application/json'
      }
    })
    .then((res) => {
      fileDownload(res.data, filename)
    })
  }
  

  function handleWindowSizeChange() {
      setWidth(window.innerWidth);
     //console.log("width",width)
  }
  const isMobile = width <= 768;

 

useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
   //console.log("fileImg.current ", fileImg )
   const uploadFiles = async (file) => {
    try 
    {
     //console.log("uploadFiles", files.current)
      var srvRoute = '/revue/upload/';
      let formData = new FormData();
      formData.append("file", file);
      formData.append("type","image/jpg")
      formData.append('email', props.user.email)
      formData.append('password', props.user.password)

      var filename = uuidv4() + "."+ getFileExtension(file.name) 
      
      var headers = {
              'Filename': filename,
             // 'Content-Type': file.type,
            // 'accept': 'application/json',
            //  'Content-Type': 'multipart/form-data',
              'enctype': "multipart/form-data",
            //  "Content-Type":"application/x-www-form-urlencoded",
              // "Access-Control-Allow-Origin": "*",
             }
        await timeout(3000)
            
      let res = await srvConn(srvRoute, formData , headers);
      URL.revokeObjectURL(file.preview)
      files.current= []
      //console.log('loading',loading)
      
      alertDiv(res)
     // setLoading(false)

      

  } catch (err) {
   //console.log('err', err);
  }
    URL.revokeObjectURL(file.preview)
    
      
}
 

 //console.log('files ',files.current)


 
    return () => { 

      if(files.current.length > 1) {

       //console.log("Alert")
        freePackageAlert()
      } else {

      
      files.current.forEach(async file => {
        //console.log('loading', loading)
        setLoading(true)
      
     //console.log('files', file.name)
      await uploadFiles(file)
      files.current = []
      URL.revokeObjectURL(file.preview)
        
      });
  
    }
  }
  }, [files.current, props.user]);


  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount



   //console.log('fileImg',fileImg)
    return () => { 
      window.removeEventListener('resize', handleWindowSizeChange);

  }
  }, [fileImg, handleWindowSizeChange, loading]);

  return (
    <section className="container">
     {!loading ? (<> <div {...getRootProps({className: 'dropzone justify-content-center'})}>
        <input {...getInputProps()} />
        <p><img src="/images/upload-icon.png" alt={t('eval_photo.dropzone_msg')} className="justify-content-center p-10 m-10" width={100} height={100}/></p><p>{t('eval_photo.dropzone_msg')} </p>
      </div></>) : (<></>)}
     
      
      <aside style={thumbsContainer} className='d-flex justify-content-center'>
        {//thumbs
        }

       <div class={`loading-anim gelatine ${(loading) ?('d-block') :('d-none')}`}>
          <span className='visually-hidden'>Loading...</span>
         </div>
        
       
        
      </aside>
      <div ref={msg} className="alert alert-success alert-dismissible fade d-none">
     <strong>{title}</strong>  {message} <button type="button" className="close" aria-label="Close" onClick={(e) => dismissClick(e)}>
<span aria-hidden="true">×</span>
</button></div>
{ (fileImg === null) ? (<></>) : (
<div className='row w-auto'>
<div className="col-sm">
<div ref={resultCard} className="fade show" style={{width:`${isMobile ? (300) : (400) }`, margin:"20px 0 24px 0"}}>

  <div className="card-img-top w-100 bg-image d-flex justify-content-center align-items-center bg-image card shadow-1-strong"  style={{backgroundImage:'url("'+ process.env.REACT_APP_DOMAIN+fileImg+'")', height:400, backgroundSize: 'cover', backgroundPosition: "right bottom",
            overflow: 'hidden'}} >
  <div className="card-body">
    
    <a href='/#'  className="btn btn-primary p-2" onClick={(e) => handleDownload(e, process.env.REACT_APP_DOMAIN +fileImg, 'revue_evaluation.png')} ><i className="fa fa-download" />{t("eval_photo.btn_download_file")}</a> <a href={`/verify${fileImg.replace('.png', '')}`} className="btn btn-secondary p-2">{t("eval_photo.btn_link")}</a> 
  </div>
</div>
</div>
</div>
<div className="col-sm">
  {(loading) ? (<></>) : (<><HighchartsReact
      highcharts={Highcharts}
      options={isMobile ? options : options_desktop}
      ref={chartComponentRef}
      {...props} /></>)}

</div>
</div>
)}

       
    </section>
  );
}

export default Basic;